<template>
    <div class="footer">
        <div class="contat-footer">
            <div class="metcare">
            <div class="logo">
                <img src="@/assets/img/logo.png" alt="">
            </div>
            <div class="telefone">
                <div class="image-phone"><img src="@/assets/img/celular.png" alt=""></div>
                <p>921 000 000</p>
            </div>
            <p>Luanda - Angola</p>
            <p>Vila Alice, Rangel</p>
            <p class="contact">contact@met-care.com</p>
        </div>
        <div class="subscreva">
            <span>Subscreva a Newslatter</span>
            <p>Lorem ipsum dolor sit amet consectetur.</p>
            <div class="input-icon">
        <i class="fas fa-envelope"></i> <!-- Ícone de busca do FontAwesome -->
        <input type="text" placeholder="Seu Email">
    </div>
            <div class="botaocontinua">
                <a href="#">CONTINUAR</a>
            </div>
        </div>
        <div class="navegacao">
            <span>Navegação</span>
            <p>Home</p>
            <p>Sobre nós</p>
            <p>Ocorrencias</p>
            <p>Saúde-Care</p>
            <p>Met-Alerta</p>
        </div>
        <div class="contacte">
            <span>Conecte-se conosco</span>
        </div>
        </div>
        <div class="power">
            <div class="copy-power">
                <p>© 2024 – <span>Met-Care.</span>  Todos Direitos Reservados</p>
                <p>Powered By: Vibe Agency</p>
            </div>
        </div>
 
    </div>
</template>

<script>
export default{
    name:'footerComponent'
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.footer{
margin-top: 5%;
background-color: $primary-color;
color: white;
flex-wrap: wrap;
width: 100%;
font-family: 'MuseoModerno1', monospace !important;
}
 span{
    font-family: 'MuseoModerno', monospace !important;
    margin-bottom: 20%;
}

.contat-footer{
    padding: 30px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
}
.telefone .image-phone{
    padding-right: 2%;

}
.telefone{
    display: flex;
}
.contact {
    position: relative;
    width: 50%;
}

.contact::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%; /* Define o comprimento da borda inferior */
    border-bottom: 1px solid white; /* Define a borda inferior */
}
input{
    padding: 7px;
    border-radius: 5px;
    border: none;
}
input:focus{
    border: none;

}
.input-icon {
            position: relative;
            width: 100%;
        }

        .input-icon input {
            width: 70%;
            padding-left: 30px; /* Ajuste o padding para dar espaço ao ícone */
            box-sizing: border-box;
        }

        .input-icon i {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none; /* Para que o ícone não interfira na interação com o input */
            color: #aaa; /* Cor do ícone */
        }
.metcare{
    width: 30%;
    text-align: start;

 }
 .subscreva{
    width: 30%;
    text-align: start;

 }
 .navegacao{
    width: 30%;
    text-align: start;

 }
 .contacte{
    width: 25%;
    text-align: end;

 }
.logo{
            margin-bottom: 10%;
        }
.power{
    border-top: 1px solid white;
}
.copy-power{
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
}

.botaocontinua{
    margin-top: 5%;
    background-color: $font-color;
    width: 35%;
    padding: 10px;
    border-radius: 5px;
    text-align: center;

}
.botaocontinua a{
    text-decoration: none;
    color: white;
    font-family: 'MuseoModerno1', monospace !important;

}

</style>