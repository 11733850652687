<template>
    <div class="blog">
        <div class="blogdesc">
         <div class="ultimasnoticias">
            <span>Blog</span>
            <h3>Últimas Notíciais</h3>
         </div>
         <div class="linha">
            <img src="@/assets/img/linha.png" alt="">
         </div>
         <div class="noticiadispositivo">
            <p>
                Desde dispositivos médicos de ponta até diagnóstico laboratorial e soluções de imagem, oferecemos suporte técnico especializado e representações exclusivas das principais marcas globais.
            </p>
         </div>
        </div>
      <div class="cards-blogs">
        <div class="card" style="width: 20rem; ">
  <img src="@/assets/img/blog1.png" class="card-img-top" alt="...">
    <div class="blog-conteudo container">
                <div class="datas">
    <div class="calendario">
        <img src="@/assets/img/calendario.png" class="card-img-top" alt="...">
    </div>
    <div class="dataPublicacao ">
        <p>13 DE  Junho 2024</p>
    </div>
  </div>
  <div class="">
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

  </div>
  <div class="botaoSaibaMais">
      <a href="">LER MAIS</a>
  </div>

   </div>
</div>
 <!-- Blog 2-->

 <div class="card" style="width: 20rem; ">
  <img src="@/assets/img/blog1.png" class="card-img-top" alt="...">
    <div class="blog-conteudo container">
                <div class="datas">
    <div class="calendario">
        <img src="@/assets/img/calendario.png" class="card-img-top" alt="...">
    </div>
    <div class="dataPublicacao ">
        <p>13 DE  Junho 2024</p>
    </div>
  </div>
  <div class="">
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

  </div>
  <div class="botaoSaibaMais">
      <a href="">LER MAIS</a>
  </div>

   </div>
</div>

    <!-- Blog 3-->
    <div class="card" style="width: 20rem; ">
  <img src="@/assets/img/blog1.png" class="card-img-top" alt="...">
    <div class="blog-conteudo container">
                <div class="datas">
    <div class="calendario">
        <img src="@/assets/img/calendario.png" class="card-img-top" alt="...">
    </div>
    <div class="dataPublicacao ">
        <p>13 DE  Junho 2024</p>
    </div>
  </div>
  <div class="">
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

  </div>
  <div class="botaoSaibaMais">
      <a href="">LER MAIS</a>
  </div>

   </div>
</div>


      </div>

   <div class="botarVermais">
    <vermaisbottomComponentVue/>
   </div>
     
    </div>
    
</template>

<script>
import vermaisbottomComponentVue from '../elements/vermaisbottomComponent.vue'
export default{
    name:'blogComponent',
    components:{
        vermaisbottomComponentVue,
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.blog{
    width: 90%;
    margin-top: 4%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'MuseoModerno', monospace !important;

}
.blogdesc{
    display: flex;
    text-align: start;
    margin-bottom: 4%;
}
.blog-conteudo{
    display: grid;
    margin-top: 5%;
}
.ultimasnoticias{
    width: 50%;
    color: $primary-color;
}
.ultimasnoticias span{
    color: $font-color;
}
.noticiadispositivo{
    width: 40%;
    color: $text-color;
}
.dataPublicacao{
    color: rgba(136, 136, 136, 1);
}
.card-text{
    text-align: start;
    color: $primary-color;
}
.cards-blogs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.botaoSaibaMais{
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 2%;
    margin-bottom: 2%;
}
.botaoSaibaMais a{
    text-decoration: none;
    color: $font-color;

}
.calendario{
    width: 5%;
}
.calendario img{
    width: 100%;
}
.datas{
    display: flex;
}
.dataPublicacao{
    padding-left: 1%;

}
.linha{
    width: 2%;
    height: 9vh;
}
.linha img{
    height: 100%;
}
.botarVermais{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 4%;
    width: 40%;
}
</style>