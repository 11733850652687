<template>
   <div class="botao">
    <button>Saber Mais</button>

   </div>
</template>

<script>
 export default{
    name:'vermaisbottomComponent',
 }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
    .botao{
        width: 35%;

    }
    button{
        background-color:$font-color;
        padding: 8%;
        width: 100%;
        border-radius: 6px;
        border: crimson;
        color: white;
        font-family: 'MuseoModerno', monospace !important;

    }
</style>

