<template>
  <div class="carousel-container max-width-container">
    <Carousel
      ref="carousel"
  
      :wrap-around="true"
      :show-arrows="false"
  @slideChange="onSlideChange" 
    >

    <!--       :autoplay="2000" Ativar o S     -->
      <Slide v-for="(slide, index) in slides" :key="index">
        <div class="carousel__item">
          <img :src="slide.image" alt="Slide Image" class="carousel__image" />
          <div class="carousel-caption d-none d-md-block container-fluid">
            <div class="copy-principal ">
              <h1>{{ slide.label }}</h1>
            <h1>{{ slide.label2 }}</h1>
            <p>{{ slide.content }}</p>
            <button @click="handleButtonClick(index)" class="slide-button">{{ slide.buttonText }}</button>
            </div>
          </div>
     
          <!--Cardes  -->
        </div>
   
      </Slide>
          
    </Carousel>

    <!-- Botões Personalizados -->
    <div class="carousel-buttons content">
      <button @click="prev" class="arrow-button left-arrow"><i class="fa-solid fa-arrow-left"></i></button>
      <cardesComponentVue/>
      <button @click="next" class="arrow-button right-arrow"><i class="fa-solid fa-arrow-right"></i></button>
    </div>



 

  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import socorroImage from '@/assets/img/socorro.png';

// importar component
import navComponentVue from '../navbar/navComponent.vue';
import cardesComponentVue from './cardesComponent.vue';

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    Carousel,
    Slide,
    Pagination,
    navComponentVue,
    cardesComponentVue
  },
  setup() {
    const carousel = ref(null);
    const currentSlide = ref(0);

    onMounted(() => {
      console.log(carousel.value); // Certifique-se de que o carrossel está sendo montado corretamente
    });

    const next = () => {
      if (carousel.value && carousel.value.next) {
        carousel.value.next();
      } else {
        console.error('carousel.next is not a function');
      }
    };

    const prev = () => {
      if (carousel.value && carousel.value.prev) {
        carousel.value.prev();
      } else {
        console.error('carousel.prev is not a function');
      }
    };

    const onSlideChange = (index) => {
      currentSlide.value = index;
    };

    const handleButtonClick = (index) => {
      console.log(`Button clicked on slide ${index}`);
      // Adicione aqui a lógica que você deseja executar ao clicar no botão de um slide específico
    };

    return {
      carousel,
      next,
      prev,
      currentSlide,
      onSlideChange,
      handleButtonClick,
      slides: [
        {
          image: socorroImage,
          label: 'A RESPOSTA RÁPIDA ',
          label2:'À SUA URGÊNCIA',
          content: 'A MET-CARE esta no seu caminho para se tornar o líder de gestão de risco pré-hospitalar e provedor de assistência médica de emergência e resgates no Mercado Angolano.',
          buttonText: 'LER MAIS'
        },
        {
          image: socorroImage,
          label: 'Second slide label',
          content: 'Some representative placeholder content for the second slide.',
          buttonText: 'LER MAIS'
        },
        {
          image: socorroImage,
          label: 'Third slide label',
          content: 'Some representative placeholder content for the third slide.',
          buttonText: 'LER MAIS'
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
/*
.max-width-container {
    max-width: 2000px; /* Ajuste este valor conforme necessário 
    margin: 0 auto; /* Centraliza o contêiner 
}*/
.carousel__item {
  width: 100%;
}

.carousel__image {
  width: 100%;
  height: auto;
}
.carousel__item{
  height: 100vh; /* Editar aqui */
}
.carousel-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
  
}

.arrow-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}



.slide-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  width: 50%;
  background-color: $font-color;
  height: 55px;
}
.copy-principal, h1{
  width: 100%;
  display: grid;
  text-align: start;
  font-family: 'MuseoModerno', monospace !important;
}
.copy-principal{
  font-size: 1rem;

}
.copy-principal h1{
  font-size: 3rem;
  font-weight: 700 ;
}

.carousel-container{
  border-bottom: $primary-color solid 14px;
}
.carousel-caption{

  display: flex !important;
  align-items: start;
  width: 35%;
  height: 60vh;
  margin-left: -10%;
}

/* Estilo dos cards Tamanho de Cards

@media (min-width: 576px) {
    .max-width-container {
        max-width: 540px; 
      }
}

@media (min-width: 768px) {
    .max-width-container {
        max-width: 720px; /* Dispositivos médios
    }
}

@media (min-width: 992px) {
    .max-width-container {
        max-width: 960px; /* Dispositivos grandes 
    }
}

@media (min-width: 1200px) {
    .max-width-container {
        max-width: 1140px; /* Dispositivos extra grandes 
    }
}

@media (min-width: 1400px) {
    .max-width-container {
        max-width: 1320px; /* Dispositivos maiores 
    }
}




*/



</style>
