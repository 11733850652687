<template>
    <div class="ocorrencia">
        <div class="imagemFundo">
            <img :src="imagemFundoUrl" alt="">
        </div>
        <div class="ocorrencias-content">
            <h3>Ocorrências</h3>
            <div class="elements" @click="alterarImagem(require('@/assets/img/socorro.png'))">
                <div class="icon">
                    <img src="@/assets/img/user1.svg" alt="">
                </div>
                <div class="desc">
                    <span>Lorem ipsum dolor sit</span>
                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
            </div>
            <div class="elements" @click="alterarImagem(require('@/assets/img/ocorrencia.png'))">
                <div class="icon">
                    <img src="@/assets/img/user1.svg" alt="">
                </div>
                <div class="desc">
                    <span>Lorem ipsum dolor sit</span>
                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
            </div>
            <div class="elements" @click="alterarImagem(require('@/assets/img/socorro.png'))">
                <div class="icon">
                    <img src="@/assets/img/user1.svg" alt="">
                </div>
                <div class="desc">
                    <span>Lorem ipsum dolor sit</span>
                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
            </div>
            <div class="elements" @click="alterarImagem(require('@/assets/img/socorro.png'))">
                <div class="icon">
                    <img src="@/assets/img/user1.svg" alt="">
                </div>
                <div class="desc">
                    <span>Lorem ipsum dolor sit</span>
                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Telefone   -->
    <div class="telefone">
        <img src="@/assets/img/telefone.png" alt="">
    </div>
    <div class="video">
        <img src="@/assets/img/video.png" alt="">
    </div>
</template>
<script>
export default {
    name: 'ocorrenciasComponent',
    data() {
        return {
            imagemFundoUrl: require('@/assets/img/ocorrencia.png') // URL inicial da imagem
        };
    },
    methods: {
        alterarImagem(novaUrl) {
            this.imagemFundoUrl = novaUrl;
        }
    }
}
</script>



<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.ocorrencia {
    height: 100vh;
    position: relative;
}
.imagemFundo {
    width: 100%;
}
.imagemFundo img {
    width: 100%;
    height: 100vh;
}
.icon {
    background-color: rgba(245, 245, 245, 1);
    padding: 20px;
    border-radius: 50%;
}
.icon img {
    width: 100%;
    height: 100%;
}
.elements:hover .icon img {
    filter: brightness(0) saturate(100%) invert(98%) sepia(54%) saturate(79%) hue-rotate(340deg) brightness(116%) contrast(96%);}
.elements:hover .icon{
    background-color: red;
}
.desc {
    width: 70%;
    margin-left: 3%;
}
.desc span {
    color: $primary-color;
}
h3{
    text-align: start;
    padding-left: 3%;
    font-family: 'MuseoModerno', monospace !important;
    color: $primary-color;


}
.ocorrencias-content {
    font-family: 'MuseoModerno', monospace !important;
    display: grid;
    cursor: pointer;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    width: 40%;
    position: absolute;
    margin-left: 10%;
    bottom: 0;
    transform: translateX(-50%); /* Ajusta o alinhamento para centralizar exatamente */
    height: 80%;
    left: 20%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 2%;
    padding-top: 1%;
}
.elements {
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: start;
    background-color: rgba(244, 249, 255, 1);
    width: 100%;
    padding: 20px;
    border-radius: 15px;
}
.elements:hover {
    border: 1px solid $primary-color;
}

/* Telefone */
.telefone{
    width: 100%;
}
.telefone img{
    width: 100%;
}
.video{
    width: 100%;
}
.video img{
    width: 100%;
}
</style>
