<template>
    <div class="cards">
        <div class="card">
            <h3>Bem-Vindo à Met-Care</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo eligendi obcaecati</p>
        </div>
        <div class="card">
            <h3>Bem-Vindo à Met-Care</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo eligendi obcaecati</p>
        </div>
        <div class="card">
            <h3>Bem-Vindo à Met-Care</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo eligendi obcaecati</p>
        </div>
    </div>
</template>

<script>
    export default{
        name:'cards'
    }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

    .cards{
  z-index: 1;

  width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-left: 50%;
    margin-top: 30%;
    
    }
    .card{
        width: 30%;
        background-color: white;
        text-align: start;
        padding: 14px;
        border-bottom: 12px solid $font-color;
        color: $primary-color !important;
        border-top: none;
    }
    .card h3{
        font-size: 1.2rem;
        font-weight: bold;
    }
    .card p{
        font-size: 12px;
        font-weight: bold;

    }
</style>