<template>
    <div class="nossoservicos">
        <div class="container-fluid">
            <div class="servico-content">
                <div class="servicos">
                    <span>NOSSOS SERVIÇOS</span> 
                    <h2>Explore a nossa gama de serviços de saúde.</h2>
                </div>
                <hr>
                <div class="oferta">
                    <p>Oferecemos uma variedade de planos de saúde personalizados, incluindo o Plano Kandengue, Plano Muadie e Plano Kota, projetados para atender às diversas necessidades de nossos clientes.</p>
                </div>
            </div>
            <div class="imagemServicos">
                <div class="servicoImage">
                    <img src="@/assets/img/assistencia.webp" alt="Imagem de Asistencia Met-Care">
                    <div class="overlay"><div class="imageAnima"><img src="@/assets/img/assisttencianima.png"  alt="Imagem de Serviços Met-Care"></div></div>
                </div>
                <div class="servicoImage">
                    <img src="@/assets/img/gestao.png" alt="Imagem de Gestão Met-Care">
                    <div class="overlay"><div class="imageAnima"><img src="@/assets/img/cuidadoanima.png"  alt="Imagem de Serviços Met-Care"></div></div>
                </div>
                <div class="servicoImage">
                    <img src="@/assets/img/gestao.png" alt="Imagem de Gestão Met-Care">
                    <div class="overlay"><div class="imageAnima"><img src="@/assets/img/gestaoanima.png"  alt="Imagem de Serviços Met-Care"></div></div>
                </div>
                <div class="servicoImage">
                    <img src="@/assets/img/servico.png"  alt="Imagem de Serviços Met-Care">
                    <div class="overlay"><div class="imageAnima"><img src="@/assets/img/helecopter.png"  alt="Imagem de Serviços Met-Care"></div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.container-fluid {
    width: 100%;
}

.nossoservicos {
    background-color: #EDF0F5;
    font-family: 'MuseoModerno', monospace !important;
}

.servico-content {
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 3%;
}

.servicos {
    width: 43%;
    color: $primary-color;
    border-right: 1px solid #6F6D71;
}

.servicos span {
    color: $font-color !important;
}

.oferta {
    width: 43%;
    color: #6F6D71;
}

.servicoImage {
    width: 20%;
    position: relative; /* Adicionado para posicionar a sobreposição corretamente */
    overflow: hidden; /* Garantir que a sobreposição não ultrapasse o limite da imagem */

}

.imagemServicos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 5%;
    border: none;
}

.servicoImage img {
    width: 100%;
    display: block;
}
.overlay {
    background-color: $primary-color;
    position: absolute;
    top: 0;
    right: 0; /* Posiciona a sobreposição no lado direito da imagem */
    bottom: 0;
    left: 40; /* A sobreposição começa a partir da esquerda */
    width: 0; /* Largura inicial da sobreposição */
    height: 100%; /* Altura da sobreposição */
    opacity: 0; /* Invisível inicialmente */
    -webkit-transition: width 0.3s ease, opacity 0.3s ease;
    transition: width 0.3s ease, opacity 0.3s ease;
}

.servicoImage:hover .overlay {
    width: 100%; /* Aumenta a largura da sobreposição para cobrir toda a imagem ao passar o mouse */
    opacity: 1; /* Torna a sobreposição visível */
}
.imageAnima{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20%;
}
.imageAnima img{
    width: 80%;
}
</style>
