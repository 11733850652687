<template>
    <div class="plano">
        <div class="descplano">
            <span>Nossos Planos de Saúde</span>
            <h2>Escolha Saúde, Escolha Met-Care: Proteção para Você e Sua Família!</h2>
        </div>
        <div class="imagemplano">
            <div class="planos">
                <img src="@/assets/img/kandengue.png" alt="">
                <div class="overlay"></div>
                <div class="element"></div>
                <div class="desc">Plano <br> Kandengue</div>
            </div>
            <div class="planos">
                <img src="@/assets/img/Plano_Muadie.webp" alt="">
                <div class="overlay"></div>
                <div class="element"></div>
                <div class="desc">Plano <br> Muadié</div>
            </div>
            <div class="planos">
                <img src="@/assets/img/Plano_Kota.png" alt="">
                <div class="overlay"></div>
                <div class="element"></div>
                <div class="desc">Plano<br>Kota</div>
            </div>
            <div class="planos">
                <img src="@/assets/img/Plano_Kandengue_Plus.png" alt="">
                <div class="overlay"></div>
                <div class="element"></div>
                <div class="desc">Plano <br> Kandengue PLUS</div>
            </div>
            <div class="planos">
                <img src="@/assets/img/Plano_Muadie_PLUS.webp" alt="">
                <div class="overlay"></div>
                <div class="element"></div>
                <div class="desc">Plano  <br>Muadié  PLUS</div>
            </div>
            <div class="planos">
                <img src="@/assets/img/Plano_Kota_PLUS.png" alt="">
                <div class="overlay"></div>
                <div class="element"></div>
                <div class="desc">Plano  <br>Kota  PLUS</div>

            </div>
        </div>
        
        
    </div>
</template>

<script>
 export default {
    name: 'planoComponent'
 }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.descplano {
    padding-top: 4%;
    padding-bottom: 4%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'MuseoModerno', monospace !important;

}

.descplano span {
    color: $font-color;
    font-size: 1.5rem;
}
.descplano h2{
    color: $primary-color;
}
.imagemplano {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
}

.planos {
    width: 30%;
    margin-bottom: 10px;
    position: relative; /* Necessário para posicionar a .overlay corretamente */
    cursor: pointer;

}

.planos img {
    width: 100%;
}

.overlay {
    background: rgba(253, 14, 31, 0.8);
    position: absolute;
    top: 0;
    right: 0; /* Posiciona a sobreposição no lado direito da imagem */
    bottom: 0;
    left: 0; /* A sobreposição começa a partir da esquerda */
    width: 0; /* Largura inicial da sobreposição */
    height: 100%; /* Altura da sobreposição */
    opacity: 0; /* Invisível inicialmente */
    -webkit-transition: width 0.3s ease, opacity 0.3s ease;
    transition: width 0.3s ease, opacity 0.3s ease;
}
.element{
    background-color: rgba(216, 15, 34, 1);
    position: absolute;
    height: 30%;
    width: 20%;
    bottom: 0;
}

.desc{
    position: absolute;
    height: 30%;
    width: 60%;
    bottom: 0;
    padding-top: 5%;
    padding-left: 11%;
    font-weight: bold;
    color: white;
    font-size: 1.5rem;
    font-family: 'MuseoModerno', monospace !important;
    text-align: start;

}
.planos:hover .element{
    background-color: #f6263416;
} 
.planos:hover .overlay {
    width: 100%; /* Aumenta a largura da sobreposição para cobrir toda a imagem ao passar o mouse */
    opacity: 1; /* Torna a sobreposição visível */
}
</style>
