<template>
<div>
  <navComponentVue/>
  <router-view/>
  <footerComponentVue/>
</div>
</template>

<script>
    import footerComponentVue from '@/components/footer/footerComponent.vue'
      import navComponentVue from './components/navbar/navComponent.vue'

  export default{
    components:{
      footerComponentVue,
      navComponentVue
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}



</style>
