<template>
    <div class="quemsomos container-flex">
        <div class="quemsomosContent">
          <div class="imagem-funcionario">
            <img src="@/assets/img/funcionarios.png" alt="Imagem de Funcionarios Met-care">
          </div>
          <div class="conteudo">
            <span>SOBRE NÓS</span>
            <h2>Somos a Sua Companheira de Saúde a Cada Passo!</h2>
            <p>Lorem ipsum dolor sit amet consectetur. Feugiat ut tellus ac scelerisque dui nullam lobortis elit eu. Quis pharetra elementum ultrices scelerisque libero. Massa porttitor in nulla amet justo.</p>
            <p>Nisi elit iaculis phasellus sed. Pharetra elit posuere fermentum consequat. Lacus gravida nibh in nam in nunc diam. Commodo sed.</p>
            <vermaisbottomComponent/>
          </div>
        </div>
      
    </div>
</template>

<script>
import vermaisbottomComponent from '../../elements/vermaisbottomComponent.vue'
 
    export default{
        name:'quemsomosComponent',

        components:{
            vermaisbottomComponent,
        }
    }
</script>


<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.quemsomos{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;

}
.imagem-funcionario{
    width: 35%;
}
.imagem-funcionario img{
    width: 100%;
}
.quemsomosContent{
    display: flex;
    justify-content: space-evenly;
justify-items: center;
align-items: center;
}
.conteudo{
    text-align: start;
    width: 35%;
    font-family: 'MuseoModerno', monospace !important;

}
.conteudo span{
color: $font-color;
font-weight: bold;
}
.conteudo h2{
    color: $primary-color;
}
.conteudo p{
    color: black;
}
</style>