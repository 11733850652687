<template>
<div>
<headerComponent/>

<quemsomosComponent/>
<nossosservicosComponent/>
<planoComponent/>
<ocorrenciasComponent/>
<blogComponent/>

</div>
</template>

<script>
// importar classes
import HeaderComponent from '@/components/header/headerComponent.vue'
import quemsomosComponent from '@/components/home/quemsomos/quemsomosComponent.vue'
import nossosservicosComponent from '@/components/home/servicos/nossosservicosComponent.vue'
import planoComponent from '@/components/home/plano/planoComponent.vue'
import ocorrenciasComponent from '@/components/home/ocorrencias/ocorrenciasComponent.vue'
import blogComponent from '@/components/blog/blogComponent.vue'




export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    quemsomosComponent,
    nossosservicosComponent,
    planoComponent,
    ocorrenciasComponent,
    blogComponent,


  }
}
</script>
