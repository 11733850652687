<template>
    <div class="nav-content content ">
      <nav class="navbar navbar-expand-lg bg-body-tertiary container-fluid element-nav">
        <div class="container-fluid">
          <a class="navbar-brand" href="#"><img src="@/assets/img/logo.png" alt="Logo"></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/">HOME</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/About">SOBRE NÓS</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/Saudecare">SAÚDE-CARE</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/servico">SERVIÇOS</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">MET-ALERTA</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contacto">CONTACTO</a>
              </li>
          
            </ul>
          </div>
          <div class="entrar">
            <a class="nav-link" href="#">ENTRAR</a>

          </div>
        </div>
      </nav>
    </div>
  </template>
  

<script>

</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.max-width-container {
  max-width: 100%; /* Ajuste este valor conforme necessário */
  margin: 0 auto; /* Centraliza o contêiner */
}

nav {
  background-color: $primary-color !important;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  padding: 2%;
  border-radius: 20px 20px;
  width: 90%;
  position: fixed; /* Para fixar o elemento no topo da página */
  left: 0; /* Definindo as bordas esquerda e direita para 0 */
  right: 0;
  margin-left: auto; /* Isso irá centralizar o elemento */
  margin-right: auto;
  z-index: 800 !important;
}

.nav-content {
  position: relative; /* Não necessário definir z-index aqui */
  width: 100%;
  margin-top: 1%;
  justify-content: space-around;
}

.navbar-collapse {
  display: flex;
  padding-left: 25%;
}

ul li a {
  color: white !important;
  font-family: "MuseoModerno" !important;
}

.entrar {
  height: 10vh;
  display: flex;
  color: white;
  align-items: center;
  width: 8%;
  justify-content: center;
  font-weight: bold;
  background-color: $font-color;
  font-family: "MuseoModerno" !important;
}

.cards {
  position: relative;
  z-index: 1; /* Menor que o nav */
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-left: 50%;
  margin-top: 30%;
}

.card {
  width: 30%;
  background-color: white;
  text-align: start;
  padding: 14px;
  border-bottom: 12px solid $font-color;
  color: $primary-color !important;
  border-top: none;
}

.card h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.card p {
  font-size: 12px;
  font-weight: bold;
}
</style>
